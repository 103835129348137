import React from "react";

function Navbar() {
  var page = window.location.pathname;
  
  return (
    <nav className="navbar navbar-expand-md navbar-expand-lg navbar-expand-xl navbar-expand-xxl fixed-top">
      <div className="container-fluid c">
        <a className="navbar-brand" href="/">
          <img
            src={require("../assets/logos/blue-osa.png")}
            id="logo"
            alt="osa_logo"
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="offcanvas offcanvas-end"
          tabIndex="-1"
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
              <img
                src={require("../assets/logos/blue-osa.png")}
                id="logo"
                alt="osa_logo"
              />
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
            <ul
              id="pageLink"
              className="navbar-nav justify-content-center flex-grow-1 pe-3"
            >
              <li className="nav-item mx-2">
                <a className={page === "/" ? "nav-link active" : "nav-link"} aria-current="page" href="/">
                  Home
                </a>
              </li>
              <li className="nav-item mx-2">
                <a id="abt-link" className={page === "/about" ? "nav-link active" : "nav-link"} href="/about">
                  About
                </a>
              </li>
              <li className="nav-item mx-2">
                <a className={page === "/services" ? "nav-link active" : "nav-link"} href="/services">
                  Services
                </a>
              </li>
              <li className="nav-item mx-2">
                <a className={page === "/partners" ? "nav-link active" : "nav-link"} href="/partners">
                  Partners
                </a>
              </li>
              <li className="nav-item mx-2">
                <a className={page === "/projects" ? "nav-link active" : "nav-link"} href="/projects">
                  Projects
                </a>
              </li>
            </ul>
            <a href="/contact" className="contact-btn my-auto">
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
