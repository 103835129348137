const GalleryData = [

    {
        id:1,
        img:require("../assets/images/water.jpg"),
        header:"Assess Risks For Various Industries",
        width:"300px",
        height:"200px",
        // body:"We also keep you informed throughout the entire process, ensuring that you are always up-to-date on the status of your claim."
    },
    {
        id:2,
        img:require("../assets/images/electronic.jpg"),
        header:"Electronic Equipment",
        width:"300px",
        height:"200px",
        // body:"We also keep you informed throughout the entire process, ensuring that you are always up-to-date on the status of your claim."
    },
    {
        id:3,
        img:require("../assets/images/fire2.jpg"),
        header:"Fire and Allied Perils and Burglary",
        width:"300px",
        height:"200px",
        // body:"We also keep you informed throughout the entire process, ensuring that you are always up-to-date on the status of your claim."
    },
    {
        id:4,
        img:require("../assets/images/marine.jpg"),
        header:"Marine and Cargo Survey",
        width:"300px",
        height:"200px",
        // body:"We also keep you informed throughout the entire process, ensuring that you are always up-to-date on the status of your claim."
    },
    {
        id:5,
        img:require("../assets/images/motorVehicle.jpg"),
        header:"Liability",
        width:"300px",
        height:"200px",
        // body:"We also keep you informed throughout the entire process, ensuring that you are always up-to-date on the status of your claim."
    },
    {
        id:6,
        img:require("../assets/images/road.jpg"),
        header:"Contractors All Risks/ Erection All Risk",
        width:"300px",
        height:"200px",
        // body:"We also keep you informed throughout the entire process, ensuring that you are always up-to-date on the status of your claim."
    },
    {
        id:7,
        img:require("../assets/images/fire.jpg"),
        header:"Fire and Allied Perils",
        width:"300px",
        height:"200px",
        // body:"We also keep you informed throughout the entire process, ensuring that you are always up-to-date on the status of your claim."
    },
    {
        id:8,
        img:require("../assets/images/goodInTransit.jpg"),
        header:"Goods in Transit",
        width:"300px",
        height:"200px",
        // body:"We also keep you informed throughout the entire process, ensuring that you are always up-to-date on the status of your claim."
    },
    {
        id:9,
        img:require("../assets/images/aviation.jpg"),
        header:"Aviation",
        // body:"We also keep you informed throughout the entire process, ensuring that you are always up-to-date on the status of your claim."
    },
    {
        id:10,
        img:require("../assets/images/motorVehicle2.jpg"),
        header:"Motor Vehicle Losses",
        width:"300px",
        height:"200px",
        // body:"We also keep you informed throughout the entire process, ensuring that you are always up-to-date on the status of your claim."
    },
    {
        id:11,
        img:require("../assets/images/machine.jpg"),
        header:"Machinery and Equipment Insurance",
        width:"300px",
        height:"200px",
        // body:"We also keep you informed throughout the entire process, ensuring that you are always up-to-date on the status of your claim."
    },
   
   
]

export default GalleryData;