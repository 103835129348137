import React from "react";

function GalleryCard(props) {
  return (
    <div className="col gallery-cols">
      <div className="gallery-card">
        <img src={props.img} alt="" />
        <h3>{props.header}</h3>
        <p>{props.body}</p>
      </div>
    </div>
  );
}

export default GalleryCard;
