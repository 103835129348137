import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function About() {
  return (
    <div>
      <Navbar />

      <div className="container" style={{ 
          background: "#c1c9e5",
          paddingBottom: 25,
          paddingTop: 25,
          marginBottom: 16,
          marginTop:30 }}>
        <div className="row">
          <div
            className="col"
            style={{       
              paddingRight: 18,
              paddingLeft: 25
            }}
          >
            <h1 style={{ fontWeight: "bold", color: "#183059"}}>About Us</h1>
            <p style={{ textAlign: "justify", paddingLeft: 31, paddingRight: 60, fontSize: "1.5rem"}}>
              "Our insurance surveyors and assessors possess the necessary qualifications"
              <br />
            </p>
          </div>
        </div>
      </div>

      <div className="about-2-dv">
        <div className="row">
          <div className="col text-center">
            <h3>2005</h3>
            <div className="circle-dv"></div>
            <p>We Registered</p>
          </div>
          <div className="col text-center">
            <h3>2021</h3>
            <div className="circle-dv"></div>
            <p>We Grew</p>
          </div>
          <div className="col text-center">
            <h3>2023</h3>
            <div className="circle-dv"></div>
            <p>Still Running</p>
          </div>
        </div>
        <hr className="connector" />
      </div>

      <div className="container" style={{ background: "#c1c9e5" }}>
  <div className="row">
    <div
      className="col"
      style={{
        marginTop: 52,
        marginRight: "-12px",
        marginBottom: 27,
        marginLeft: 33,
        textAlign: "justify"
      }}
    >
      <h1 style={{ fontWeight: "bold" }}>
        <strong>
          <span style={{ backgroundColor: "rgb(193, 201, 229)" }}>
            OUR MISSION
          </span>
        </strong>
        <br />
      </h1>
      <p style={{ marginRight: 14 }}>
        <span style={{ backgroundColor: "rgb(193, 201, 229)" }}>
          The mission of Oriental Surveyors and Assessors Limited is to provide
          excellent services at the highest proffesional quality, timely and at
          the lowest cost. Our tariffs are such that they just sustain our
          costs, yet give the best value for money to the client. Our tarrifs
          are based on the scale of fees set out by the Engineering Reigstration
          Board since we Oriental Sureveyors and Assesors are an Engineering
          firm
        </span>
        <br />
      </p>
    </div>
    <div className="col">
      <picture>
        <img
          src={require("../assets/images/serve.png")}
          style={{ marginLeft: 94 }}
        />
      </picture>
    </div>
  </div>
</div>

<div className="container" style={{ background: "#c1c9e5" }}>
  <div className="row">
    <div className="col" style={{ marginLeft: 30, marginBottom: 55 }}>
      <h1>
        <strong>Company Value</strong>
      </h1>
      <p style={{ marginRight: 18 }}>
        <span style={{ backgroundColor: "rgb(193, 201, 229)" }}></span>
      </p>
      <ol>
        <li>Ethics</li><br />
        <li>Excellence</li><br />
        <li>Receptiveness</li><br />
      </ol>
      <br />
      <p />
    </div>
    <div className="col" style={{ marginLeft: 26, marginTop: 0 }}>
      <h1 style={{ fontWeight: "bold" }}>Objectives</h1>
      <p style={{ textAlign: "justify", marginRight: 33 }}>
        Driven to provide excellent services at the highest professional
        quality, timely and at affordable prices. Our tariffs are such that they
        just sustain our price, yet give the best value for money to the client.
        Our tariffs are based on the scale of fees set out by the Engineering
        Registration Board since we Oriental Surveyors and Assessors are an
        Engineering firm.
        <br />
      </p>
    </div>
  </div>
</div>

      <div className="container py-4 py-xl-5">
        <h2 className="text-center bottom-line">Meet Our Team</h2>

        <div className="row team-row">
         
          <div className="col-md-4 col-sm-6 team-wrap mb-5">
            <div className="team-member text-center">
              <div className="team-img">
                <img
                  src={require("../assets/images/pau.jpg")}
                  alt="malorum"
                />
                <div className="overlay">
                  <div className="team-details text-center">
                 {/* <p>
                      Our web design team will spend time with our digital
                      marketing team.
                    </p> */}
                  </div>
                </div>
              </div>
              <h4 className="team-title">Paul Lucas Chogo</h4>
              <span>Director</span>
            </div>
          </div>
          {/* <!-- end team member --> */}

          <div className="col-md-4 col-sm-6 team-wrap mb-5">
            <div className="team-member text-center">
              <div className="team-img">
                <img
                  src={require("../assets/images/lucas.jpg")}
                  alt="malorum"
                />
                <div className="overlay">
                  <div className="team-details text-center">
                     {/* <p>
                      Our web design team will spend time with our digital
                      marketing team.
                    </p> */}
                  </div>
                </div>
              </div>
              <h4 className="team-title">Lucas Boaventure Jacob Chogo</h4>
              <span> Managing Director</span>
            </div>
          </div>
          {/* <!-- end team member --> */}

          <div className="col-md-4 col-sm-6 team-wrap mb-5">
            <div className="team-member last text-center">
              <div className="team-img">
                <img
                  src={require("../assets/images/laura.jpg")}
                  alt="malorum"
                />
                <div className="overlay">
                  <div className="team-details text-center">
                   {/* <p>
                      Our web design team will spend time with our digital
                      marketing team.
                    </p> */}
                  </div>
                </div>
              </div>
              <h4 className="team-title">Laura Lucas Chogo</h4>
              <span>Company Secretary</span>
            </div>
          </div>
          {/* <!-- end team member --> */}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;

const headerWrapperStyling = {
  width: "88%",
  display: "block",
  margin: "0 auto",
  textAlign: "center",
}

const imgWrapperStyling = {
   width: "72%",
   maxWidth: "72%",
   height: "60vh",
   display: "block",
   margin: "0 auto",
   overflow: "hidden",
   borderRadius: "5px",
   position: "relative",

}

const imgStyling = {
  width: "100%",
  height: "20vh",
  maxHeight: "20vh",
  objectFit: "contain",
  boxShadow: "0px 3px 6px rgba(0,0,0,0.2)"
}