import React from "react";
import "./App.scss";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Home from "./pages/Home.jsx";
import About from "./pages/About.jsx";
import Contact from "./pages/Contact.jsx";
import Projects from "./pages/Projects.jsx";
import Partners from "./pages/Partners.jsx";
import Services from "./pages/Services.jsx";

function App() {
  return (
    <Router>
    <Routes>
      <Route index element={<Home />}></Route>
      <Route path="/About" element={<About />}></Route>
      <Route path="/Services" element={<Services />}></Route>
      <Route path="/Partners" element={<Partners />}></Route>
      <Route path="/Contact" element={<Contact />}></Route>
      <Route path="/Projects" element={<Projects />}></Route>
    </Routes>
  </Router>
  );
}

export default App;
