import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import PartinershipCorousel from "../components/PartinershipCorousel";

function Portifolio() {
  return (
    <div>
      <Navbar />
      {/* <div className="row row-cols-1 row-cols-md-2 row-cols-ld-2 partiner-0-dv">
        <div className="col non-matrix-wrapper" >
          <h1 className="mb-5" >We Work With the Best Partners.</h1>
          <p className="mb-5" style={{width: "90%", fontSize: "17px"}}>
            While we are at the forefront of and specialize in design-build, we
            are very familiar with a number of delivery methods and are
            confident we can find the process that will best help you meet your
            goals.
          </p>
          <a href="/projects">
            <button className="mb-5">Learn more</button>
          </a>
        </div>
        <div className="col matrix-wrapper" >
          <div className="matrix-dv matrix-top">
            <img
              className="partiners-logo phoenix"
              src={require("../assets/logos/phoenix.PNG")}
              alt="phoenix"
            />
            <img
              className="partiners-logo britam"
              src={require("../assets/logos/britam.PNG")}
              alt="britam"
            />
            <img
              className="partiners-logo alliance"
              src={require("../assets/logos/alliance.PNG")}
              alt="alliance"
            />
          </div>

          <div className="matrix-dv matrix-center text-center">
            <img
              src={require("../assets/logos/osa-blue-trans.png")}
              alt="osa_blue"
            />
          </div>

          <div className="matrix-dv matrix-btm">
            <img
              className="partiners-logo"
              src={require("../assets/logos/heritage.png")}
              alt="heritage"
            />
            <img
              className="partiners-logo mgen"
              src={require("../assets/logos/mgen.png")}
              alt="mgen"
            />
            <img
              className="partiners-logo strategies"
              src={require("../assets/logos/strategis.PNG")}
              alt="strategies"
            />
          </div>
        </div>
      </div> */}

<div className="container" style={{ paddingBottom: 23 }}>
  <div className="row">
    <div className="col">
      <picture>
        <img
          src={require("../assets/icons/shield.png")}
          style={{
            paddingBottom: 0,
            marginLeft: 0,
            paddingLeft: 19,
            paddingRight: 38
          }}
          width={550}
        />
      </picture>
    </div>
    <div
      className="col"
      style={{
        paddingRight: 72,
        paddingLeft: 20,
        marginLeft: 28,
        paddingTop: 28
      }}
    >
      <h1>
        <strong>We collaborate with the best.</strong>
        <br />
      </h1>
      <p>
        While we are at the forefront of and specialize in design-build, we are
        very familiar with a number of delivery methods and are confident we can
        find the process that will best help you meet your goals.
        <br />
      </p>
      
    </div>
  </div>
</div>

<div
  className="container"
  style={{
    background: "#c1c9e5",
    paddingLeft: 32,
    paddingTop: 19,
    paddingBottom: 0,
    marginBottom: 15
  }}
>
  <div className="row">
    <div className="col">
      <h1 style={{ paddingTop: 11 }}>
        <strong>Why Partner with us?</strong>
        <br />
      </h1>
      <p
        style={{
          paddingBottom: 0,
          marginBottom: 35,
          paddingRight: 34,
          textAlign: "justify"
        }}
      ></p>
      <ol>
        <li>
          <u>Wide range of insurance products</u>: OSA Insurance offers a broad
          range of insurance products that can be tailored to meet the specific
          needs of our clients. Whether it's property, liability, or life
          insurance, OSA can provide the coverage that clients need to protect
          themselves against financial loss.
        </li>
        <br />
        <li>
          <u>Experienced surveyors</u>: OSA has a team of experienced surveyors
          who uses advanced technology and techniques to provide accurate and
          reliable surveying reports. These reports can be used to assess the
          value and condition of assets and properties, helping clients make
          informed decisions.
        </li>
        <br />
        <li>
          <u>Personalized service</u>: OSA prides itself on providing
          personalized service to its clients. They work closely with clients to
          understand their unique needs and offer customized solutions that meet
          those needs. This personalized approach helps clients feel confident
          that they have the right insurance coverage and surveying reports for
          their specific situation.
        </li>
        <br />
        <li>
          <u>Strong reputation</u>: OSA Insurance and Surveyors Company has a
          strong reputation for providing high-quality services and reliable
          support to its clients. Partnering with OSA can help businesses and
          individuals build trust and credibility with their own customers and
          stakeholders.
        </li>
        <br />
        <li>
          <u>Competitive pricing</u>: OSA offers competitive pricing for its
          insurance and surveying services, making it an affordable option for
          clients looking for high-quality coverage and support.
        </li>
        <br />
      </ol>
      <p />
    </div>
    <div className="col" style={{ paddingLeft: 0, marginLeft: "-194px" }}>
      <picture>
        <img
          src={require("../assets/icons/shield.png")}
          style={{
            marginRight: 0,
            paddingTop: 35,
            marginLeft: 79,
            paddingBottom: 22,
            paddingLeft: 8,
            marginTop: 107
          }}
          width={550}
        />
      </picture>
    </div>
  </div>
</div>

      {/* <div className="portifolio-2-dv text-center">
        <h1 className="mx-auto">Partnership</h1>
        <p className="mx-auto">
          In the recent past and currently, Oriental Surveyors and Assessors has
          continued to provide survey and assessment services to various
          companies
        </p>
      </div> */}
      <PartinershipCorousel />
      <Footer />
    </div>
  );
}

export default Portifolio;
