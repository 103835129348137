import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import PartinershipCorousel from "../components/PartinershipCorousel";

function Home() {
  return (
    <div>
      <Navbar />
      <div className="container-fluid section-min-h section-p-y">
        <div className="row row-cols-1 row-cols-md-2 hm-wlcm-row g-0">
          <div className="col hm-wlcm-col left" >
            <h1 style={{minWidth: "90%"}}>Are you in need of expert help?</h1>
            <p style={{minWidth: "90%"}}>
              We are a firm that specializes in carrying out professional surveys
              and assessments both for purposes of insurance as well as needs and demands of insurance companies in business entities, industries, Importers and Exporters, Individuals and son on 
            </p>
            <a href="/contact">
              <button className="get-start-btn">Get a Quote</button>
            </a>
          </div>
          <div className="col hm-wlcm-col right">
            <img
              style={{ width: "80%", position: "relative", zIndex: "1" }}
              src={require("../assets/logos/osa-logo.jpg")}
              alt="..."
            />
            <div className="behind-dv">
              <p>
                Contact{" "}
                <img
                  id="phone-24"
                  src={require("../assets/icons/icons8-hot-line-64.png")}
                  alt=""
                />
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="features-dv section-min-h section-p-y">
        <h2 className="mx-auto" ><strong>Why Choose Our Services</strong></h2>
        <div className="row row-cols-1 row-cols-md-3 g-5 ftr-row">
          <div className="col">
            <div className="card h-100 text-center ftr-card">
              <img
                src={require("../assets/icons/certification.PNG")}
                className="card-img-top ftr-img"
                alt="..."
              />
              <div className="card-body">
                <h5 className="card-title">Certification Platform</h5>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100 text-center ftr-card">
              <img
                src={require("../assets/icons/process.PNG")}
                className="card-img-top ftr-img"
                alt="..."
              />
              <div className="card-body">
                <h5 className="card-title">Easy Claim process</h5>
               
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100 text-center ftr-card">
              <img
                src={require("../assets/icons/insurance.PNG")}
                className="card-img-top ftr-img"
                alt="..."
              />
              <div className="card-body">
                <h5 className="card-title">Digital Insurance</h5>
             
              </div>
            </div>
          </div>
        </div>
        <p className="exp-year mx-auto">
          With more than <span>20+</span> years of experience
        </p>
      </div> */}

      <div className="row features-dv">
  <div className="col">
  <h2 className="mx-auto text-center" ><strong>Why Choose Our Services</strong></h2>
    <div className="container">
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <picture>
                <img
                  src={require("../assets/icons/certification.PNG")}
                  alt="certification"
                  width={300}
                />
              </picture>
              <h4 className="text-center card-title">Certified Platform</h4>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card">
            <div className="card-body">
              <picture>
                <img
                  src={require("../assets/icons/process.PNG")}
                  alt="process"
                  width={300}
                />
              </picture>
              <h4 className="text-center card-title">Easy Claim Process</h4>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card">
            <div className="card-body">
              <picture>
                <img
                  src={require("../assets/icons/insurance.PNG")}
                  alt="insurance"
                  width={300}
                />
              </picture>
              <h4 className="text-center card-title">Digital Insurance</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p className="exp-year mx-auto text-center">
          With more than <span>20+</span> years of experience
        </p>
  </div>
</div>


      <div className="row row-cols-1 row-cols-md-2 malorum">
        <div className="col">
          <h1>Services </h1>
          <p style={{width: "80%", marginTop:"-20px", marginBottom:"30px"}}>
            We offer a wide range of servicesto go with our a huge clientel
            base, Feel free to view our services and projects done
          </p>
          <a href="/services">
            <button className="services-btn">Services</button>
          </a>
          <a href="/projects">
            <button className="services-btn proj">Projects</button>
          </a>
        </div>
        <div className="col">
          <img src={require("../assets/logos/osa-logo.jpg")} alt="malorum" />
        </div>
      </div>

      <PartinershipCorousel />
      <Footer />
    </div>
  );
}

export default Home;
