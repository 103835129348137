import * as React from 'react';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import axios from 'axios';



function Contact() {

  const  [ form, setForm] = React.useState({
    email: "",
    name: "",
    subject: "",
    body: ""
  })


  const handleChanges = (event) => {
    setForm({
      ...form,
      [event.target.id]: event.target.value
    })

  }

  const rest = () => {
    setForm({
      ...form,
      email: "",
      name: "",
      subject: "",
      body: ""
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault();
   const  email = {
      name: form.name,
      email: form.email,
      subject: form.subject,
      body: form.body
    }
    
    
    axios.post('http://orientalsurveyors.co.tz/email', email).then((result) => {
      console.log(result.data)
      rest()
      alert("Email Successfully sent")
    })
  }
  return (
    <div>
      <Navbar />
      <div className="container-fluid section-min-h section-p-y">
        <div
          className="row row-cols-1 row-cols-md-2 hm-wlcm-row g-0 about-1-dv contact-1-dv"
          style={{ backgroundColor: "#fff" }}
        >
          <div className="col hm-wlcm-col left h-50">
            <h2 style={{width: "90%"}}>
              "Don't hesitate to get <span>in touch</span> with us in case you
              need more help from us."
            </h2>
          </div>
          <div className="col h-50 text-center">
            <img
              src={require("../assets/images/support-removebg-preview.png")}
              alt="engineer_image"
            />
          </div>
        </div>
      </div>
      <div className="contact-2-dv text-center">
        <h1 className="mb-5">Contact Us</h1>
       <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              id="name"
              value={form.name}
              onChange={handleChanges}
              placeholder="Name"
              required
            />
          </div>
          <div className="mb-3">
            <input
              type="email"
              className="form-control"
              id="email"
              value={form.email}
              onChange={handleChanges}
              placeholder="Email"
              required
            />
          </div>
          <div className="mb-3">
          <input
            type="text"
            className="form-control"
            id="subject"
            value={form.subject}
            onChange={handleChanges}
            placeholder="Subject"
            required
          />
          </div>
          <div className="mb-3">
            <textarea
              className="form-control"
              id="body"
              value={form.body}
              onChange={handleChanges}
              rows="6"
              placeholder="How can we help you?"
              required
            ></textarea>
          </div>
          <button type="submit" className="btn btn-outline-dark w-100">
            Send message
          </button>
        </form>
      </div>
      <div className="contact-3-dv text-center">
      <h1 className="mb-5">Visit Us <img src={require("../assets/icons/icons8-map-marker-64.png")} alt="x" /></h1>
      <div style={{width: "100%"}}>
        <iframe scrolling="no" marginheight="0" marginwidth="0" 
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2904.7902433148824!2d39.21139795981349!3d-6.7252076090602095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x185c51fbeb82e9bf%3A0x3ed479281a602b95!2sSamaki%20Wabichi%20House!5e0!3m2!1sen!2stz!4v1685941151180!5m2!1sen!2stz" width="100%" height="600" frameborder="0" title="samaki wabichi house"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
          {/* src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=76G7+375,%20Dar%20es%20salaam,%20Tanzania+(samaki%20wabichi%20house)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" width="100%" height="600" frameborder="0" title="samaki wabichi house"> */}
          <a href="https://www.maps.ie/distance-area-calculator.html">measure distance on map</a>
        </iframe></div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
