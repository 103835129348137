import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import GalleryCard from "../components/GalleryCard";
import GalleryData from "../data/GalleryData";

function MakeGalleryCard(i) {
  return <GalleryCard key={i.id} img={i.img} header={i.header} body={i.body} />;
}

var Cards = GalleryData.map(MakeGalleryCard);

function Gallery() {
  return (
    <div>
      <Navbar />
      {/* <div className="row row-cols-1 row-cols-md-2 row-cols-ld-2 gallery-0-dv">
        <div className="col">
          <h1 className="mx-auto text-center pt-5">
            Explore, learn, understand and <span>get to know us</span> better through our
            projects.
          </h1>
          <div className="left-cut-dv mx-auto"></div>
        </div>
        <div className="col">
          <div className="right-cut-dv mx-auto"></div>
          <img
            src={require("../assets/logos/osa-darkbluetransp.png")}
            alt="company_logo"
          />
        </div>
      </div> */}
      
     
  <div className="container" style={{ 
    background: "#c1c9e5",
    paddingBottom: 25,
    paddingTop: 25,
    marginBottom: 16,
    marginTop:30 }}>
  <div className="row">
    <div
      className="col"
      style={{       
        paddingRight: 18,
        paddingLeft: 25
      }}
    >
      <h1 style={{ fontWeight: "bold", color: "#183059" }}>Our Projects</h1>
      <p style={{ textAlign: "justify", paddingLeft: 31, paddingRight: 60 }}>
        Explore, learn, understand and get to know us better through our
        projects.
        <br />
      
      </p>
    </div>
  </div>
</div>


      <div className="gallery-2-dv">
        <h1 className="">Explore more.</h1>
        <div className="row row-cols-3 gallery-row">{Cards}</div>
      </div>
      <Footer />
    </div>
  );
}

export default Gallery;
