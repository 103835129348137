import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Navigation, Pagination } from "swiper";

function PartinershipCorousel() {
  return (
    <div>
    <h1 className="trusted-h text-center w-100 my-5"><span>"Trusted"</span> by many.</h1>
      <div id="carouselExample" className="carousel slide portifolio-3-dv">
      
          <Swiper
        slidesPerView={4} 
        spaceBetween={6}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 3,
            spaceBetween: 6,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 6,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 6,
          },
        }}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
        <img src={require("../assets/logos/britam.PNG")} alt="Britam" />
        </SwiperSlide>
        <SwiperSlide>
        <img src={require("../assets/logos/phoenix.PNG")} alt="Phoneix Insurance" />
        </SwiperSlide>
        <SwiperSlide>
        <img src={require("../assets/logos/nhc.PNG")} alt="National Insurance" />
        </SwiperSlide>
        <SwiperSlide>
        <img src={require("../assets/logos/icealion.png")} alt="Ice Lion Insurance" />
        </SwiperSlide>
        <SwiperSlide>
        <img src={require("../assets/logos/sanlam.PNG")} alt="Sanlam Insurance" /> 
        </SwiperSlide>
        <SwiperSlide>
        <img src={require("../assets/logos/assurance.PNG")} alt="MO Assurance" />  
        </SwiperSlide>
        <SwiperSlide>
        <img src={require("../assets/logos/heritage.png")} alt="Heritage Insurance" />
        </SwiperSlide>
        <SwiperSlide>
        <img src={require("../assets/logos/mgen.png")} alt="Mgen Tanzania" />
        </SwiperSlide>
        <SwiperSlide>
        <img src={require("../assets/logos/alliance.PNG")} alt="Alliance Insurance" /> 
        </SwiperSlide>
        <SwiperSlide>
        <img src={require("../assets/logos/jubilee.PNG")} alt="Jubliee Insurance" />
        </SwiperSlide>
        <SwiperSlide>
        <img src={require("../assets/logos/bima.PNG")} alt="Zanzibar Insurance " />
        </SwiperSlide>
        <SwiperSlide>
        <img src={require("../assets/logos/bumaco.png")} alt="Bumaco Insurance" />
        </SwiperSlide>
        <SwiperSlide>
        <img src={require("../assets/logos/strategis.PNG")} alt="Strategies Insurance" />
        </SwiperSlide>
        <SwiperSlide>
        <img src={require("../assets/logos/mayfair.png")} alt="Mayfair Insurance" />
        </SwiperSlide>
        <SwiperSlide>
        <img src={require("../assets/logos/reliance.png")} alt="Reliance Insurance" />
        </SwiperSlide>
        <SwiperSlide>
        <img src={require("../assets/logos/firstAssurance.jpg")} alt="First Assurance" />
        </SwiperSlide>
        <SwiperSlide>
        <img src={require("../assets/logos/gAInsurance.png")} alt="GA Insurance" />
        </SwiperSlide>
        <SwiperSlide>
        <img src={require("../assets/logos/meticulous.png")} alt="Meticulous Insurance" />
        </SwiperSlide>
        <SwiperSlide>
        <img src={require("../assets/logos/tanzindia.png")} alt="Tanzindia Inssurance" />
        </SwiperSlide>
        <SwiperSlide>
        <img src={require("../assets/logos/uap.jpg")} alt="UAP Insurance" />
        </SwiperSlide>
      </Swiper>
      </div>

   
    </div>
    
  );
}

export default PartinershipCorousel;
