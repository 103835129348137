import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function Services() {
  return (
    <div>
      <Navbar />

      <div className="container" style={{ 
        background: "#c1c9e5",
        paddingBottom: 25,
        paddingTop: 25,
        marginBottom: 16,
        marginTop:30 }}>
        <div className="row">
          <div
            className="col"
            style={{       
              paddingRight: 18,
              paddingLeft: 25
            }}
          >
            <h1 style={{ fontWeight: "bold", color: "#183059" }}>Our Services</h1>
            <p style={{ textAlign: "justify", paddingLeft: 31, paddingRight: 60, fontSize: "1.5rem" }}>
            You never regret being served by Us!
              <br />
            We take great pride in Our Value and Quality of work
            </p>
          </div>
        </div>
      </div>

      <div class="container">
        <div className="row">
          <div className="col">
            <h1 style={{ fontWeight: "bold", color: "#183059" }}>Area of Expertise</h1>
          </div>
        </div>
      </div>


      <div className="container">
        <div className="row">
          <div className="col">
            <p>
              <ul>
                <li>Machinery and Equipment Insurance</li><br />
                <li>Asses Risks for Various Industries</li><br />
                <li>Electronic Equipment</li><br />
                <li>Fire, Allied Perils and Burglary</li><br />
                <li>Marine and Cargo Survey</li><br />
                <li>Liability</li><br />
              </ul>
            </p>
          </div>
          <div className="col">
            <p>
              <ul>
                <li>Contracts All Risk / Erection All Risk</li><br />
                <li>Fire and Allied Perils</li><br />
                <li>Goods in Transit</li><br />
                <li>Aviation</li><br />
                <li>Motor Vehicle Losses</li><br />
              </ul>
            </p>
          </div>
        </div>
</div>



      <div
        className="container"
          style={{
              background: "#c1c9e5",
              marginBottom: 14,
              paddingBottom: 43,
              paddingTop: 35
          }}
        >
  <div className="row">
    <div className="col">
      <div
        className="card"
        style={{
          background: "rgba(255,255,255,0)",
          borderWidth: "0.3px",
          borderColor: "rgba(33,37,41,0)"
        }}
      >
        <div className="card-body">
          <picture>
            <img
              src={require("../assets/icons/shield.png")}
              style={{ paddingLeft: 0, marginLeft: 60 }}
              width={200}
            />
          </picture>
          <h4 className="text-center card-title" style={{ fontWeight: "bold" }}>
            User Satisfaction
          </h4>
          <p className="text-center card-text">
            <span style={{ backgroundColor: "rgb(193, 201, 229)" }}>
              We also keep you informed throughout the entire process, ensuring
              that you are always up-to-date on the status of your claim.
            </span>
            <br />
          </p>
        </div>
      </div>
    </div>
    <div className="col">
      <div
        className="card"
        style={{
          background: "rgba(255,255,255,0)",
          borderColor: "rgba(33,37,41,0)"
        }}
      >
        <div className="card-body" style={{ borderColor: "rgb(193,201,229)" }}>
          <picture>
            <img
              src={require("../assets/icons/shield.png")}
              style={{ marginLeft: 66 }}
              width={200}
            />
          </picture>
          <h4 className="text-center card-title" style={{ fontWeight: "bold" }}>
            Progress
          </h4>
          <p className="text-center card-text">
            <span style={{ backgroundColor: "rgb(193, 201, 229)" }}>
              We also keep you informed throughout the entire process, ensuring
              that you are always up-to-date on the status of your claim.
            </span>
            <br />
          </p>
        </div>
      </div>
    </div>
    <div className="col">
      <div
        className="card"
        style={{
          background: "rgba(255,255,255,0)",
          borderColor: "rgba(33,37,41,0)"
        }}
      >
        <div className="card-body" style={{ borderColor: "rgba(33,37,41,0)" }}>
          <picture>
            <img
              src={require("../assets/icons/shield.png")}
              style={{ marginLeft: 55 }}
              width={200}
            />
          </picture>
          <h4 className="text-center card-title" style={{ fontWeight: "bold" }}>
            Analytics
          </h4>
          <p className="card-text" style={{ textAlign: "center" }}>
            <span style={{ backgroundColor: "rgb(193, 201, 229)" }}>
              We also keep you informed throughout the entire process, ensuring
              that you are always up-to-date on the status of your claim.
            </span>
            <br />
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div className="container py-4 py-xl-5">
  <h1 className="text-center">
    We only aim to deliver the highest standards possible.
  </h1>
  <div className="row">
    <div className="col">
      <h1 className="text-center">99%</h1>
      <h5 className="text-center">Customer Satisfaction</h5>
    </div>
    <div className="col">
      <h1 className="text-center">100%</h1>
      <h5 className="text-center">Quality Service Assurance</h5>
    </div>
    {/* <div className="col">
      <h1 className="text-center">60%</h1>
      <h5 className="text-center">Customer Satisfaction</h5>
    </div> */}
  </div>
</div>



      <Footer />
    </div>
  );
}

export default Services;
const headerWrapperStyling = {
  width: "88%",
  display: "block",
  margin: "0 auto",
  textAlign: "center",
}

const btnStyling = {
  padding: "5px 7px",
  width: "27%",
  height: "35%",
  borderRadius: "5px "
}

const textStyling = {
  fontSize: "2.5rem",
  fontWeight: "bold",
}

const imgWrapperStyling = {
  width: "72%",
  maxWidth: "72%",
  height: "50vh",
  display: "block",
  margin: "0 auto",
  overflow: "hidden",
  borderRadius: "5px",
  position: "relative",
}

const imgStyling = {
  width: "100%",
  height: "50vh",
  maxHeight: "50vh",
  objectFit: "cover",
}