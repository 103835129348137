// import { ReactComponent as Fb } from "../assets/icons/icons8-facebook.svg";
function Footer() {
  return (
    <footer>
      <div className="row row-cols-2 row-cols-md-4 row-cols-lg-4 footer-row">
        <div className="col footer-col mt-3">
          <h5 className="footer-h">About</h5>
          <a className="card-text" href="/about">
            About us
          </a>
          <a className="card-text" href="/about">
            Our Team
          </a>
          <a className="card-text" href="/contact">
            Contact us
          </a>
        </div>
        <div className="col footer-col mt-3">
          <h5 className="footer-h">Services</h5>
          <a className="card-text" href="/services">
            Insurance
          </a>
          <a className="card-text" href="/services">
            Research
          </a>
        </div>

        <div className="col footer-col mt-3">
          <h5 className="footer-h">Portfolio</h5>
          <a className="card-text" href="/partners">
            Partners
          </a>
          {/* <a className="card-text" href="/projects">
            Our Works
          </a> */}
        </div>

        <div className="col footer-col mt-3">
          <h5 className="footer-h">Contact</h5>
          <p className="card-text">
            <img
              className="footer-contact-icon"
              src={require("../assets/icons/icons8-mail-48.png")}
              alt="..."
            />
               {"  "}
            info@orientalsurveyors.co.tz
          </p>
          <p className="card-text">
            <img
              className="footer-contact-icon"
              src={require("../assets/icons/icons8-phone-32.png")}
              alt="..."
            />{"  "}
            +255 784 541 541
          </p>
        </div>
      </div>
      <div className="text-center mt-5">
        <img
          src={require("../assets/logos/white-transp-osa-min.png")}
          alt="..."
        />
        <h5 id="footer-logo-h">Oriental Surveyors and Assessors.</h5>
        <p className="footer-logo-p">Insurance surveyors and Accessors</p>
        <a
          className="card-text footer-social-a"
          href="https://www.facebook.com"
        >
          <img
            className="footer-socio-icons"
            src={require("../assets/icons/icons8-facebook.svg").default}
            alt="Facebook"
          />
        </a>
        <a className="card-text footer-social-a" href="https://www.twitter.com">
          <img
            className="footer-socio-icons"
            src={require("../assets/icons/icons8-twitter.svg").default}
            alt="Twitter"
          />
        </a>
        <a
          className="card-text footer-social-a"
          href="https://www.instagram.com"
        >
          <img
            className="footer-socio-icons"
            src={require("../assets/icons/icons8-instagram.svg").default}
            alt="Instagram"
          />
        </a>
        <a className="card-text footer-social-a" href="https://www.youtube.com">
          <img
            className="footer-socio-icons"
            src={require("../assets/icons/icons8-youtube.svg").default}
            alt="Youtube"
          />
        </a>
        <a
          className="card-text footer-social-a"
          href="https://www.linkedin.com"
        >
          <img
            className="footer-socio-icons"
            src={require("../assets/icons/icons8-linkedin.svg").default}
            alt="Instagram"
          />
        </a>
      </div>
      <small id="copyright">&copy;2023 All rights reserved.</small>
    </footer>
  );
}

export default Footer;
